import * as React from "react";
import {
  Button,
  Chip,
  numberFormatter,
  pxToRem,
  Table,
  Text,
} from "@gada-saas/web-ui";
import { Box, Card, TableCell, TableRow } from "@material-ui/core";
import { useStyles } from "./styles";
import {
  useActiveStoreState,
  useGetSellInMPRecommendationListQuery,
} from "@gada-saas/web-core";
import { useRouter } from "next/router";
import { Pages } from "@common/constants";
import clsx from "clsx";
import { InfoModal } from "@components/InfoModal";

const SellInMPRecommendationSection = () => {
  const router = useRouter();
  const classes = useStyles();
  const { storeId } = useActiveStoreState();

  const { data } = useGetSellInMPRecommendationListQuery(
    {
      storeId,
      minData: 1,
    },
    { skip: !storeId, refetchOnMountOrArgChange: true }
  );

  const sellInMPRecommendationList = React.useMemo(
    () => data?.data ?? [],
    [data?.data]
  );

  const renderTableData = React.useCallback(() => {
    return sellInMPRecommendationList.map((val) => (
      <TableRow key={val.productVariantId}>
        <TableCell width="44%" className={classes.td}>
          <Box display="flex" alignItems="center">
            <Box marginRight={pxToRem(16)}>
              <img
                className={classes.productVariantImg}
                src={val.photoUrl || undefined}
                alt={val.productVariantName}
              />
            </Box>
            <Text>{val.productVariantName}</Text>
          </Box>
        </TableCell>
        <TableCell
          width="20%"
          className={clsx(classes.td, classes.salesPerMonthCell)}
        >
          {numberFormatter(val.totalSold)}
        </TableCell>
        <TableCell width="13%" className={clsx(classes.td, classes.unitCell)}>
          <Chip label={val.uomName} color="primary" borderRadius={pxToRem(4)} />
        </TableCell>
        <TableCell width="23%" className={clsx(classes.td, classes.julanCell)}>
          <Button
            variant="text"
            onClick={() => {
              router.push({
                pathname: Pages.EDIT_INVENTORY_CURATED_PRODUCT,
                query: {
                  productVariantId: val.productVariantId,
                },
              });
            }}
          >
            Jual Online
          </Button>
        </TableCell>
      </TableRow>
    ));
  }, [
    sellInMPRecommendationList,
    classes.td,
    classes.productVariantImg,
    classes.unitCell,
    classes.salesPerMonthCell,
    classes.julanCell,
    router,
  ]);

  if (sellInMPRecommendationList.length <= 0) {
    return null;
  }

  return (
    <Box marginBottom={pxToRem(80)}>
      <Box mb={pxToRem(16)} display="flex" alignItems="center">
        <Text variant="h3">Ikut Jual di Marketplace GudangAda</Text>
        <InfoModal
          title={<Text variant="h3">Ikut Jual di Marketplace GudangAda</Text>}
          content={
            <Box pb={pxToRem(48)}>
              <Text variant="body1" ink="neutralDark">
                Jenis barang dengan satuan ini sedang laku di marketplace
                GudangAda. Anda juga sudah memilikinya di Daftar Barang toko.
              </Text>
            </Box>
          }
        />
      </Box>
      <Card className={classes.card}>
        <Table
          containerClassName={classes.tableContainer}
          tableProps={{ stickyHeader: true }}
          tableHeadingCells={[
            {
              id: "productVariantName",
              content: (
                <Text variant="h5" ink="neutralPrimary">
                  Nama Barang
                </Text>
              ),
              tableCellProps: {
                className: classes.tableHeadingCell,
              },
            },
            {
              id: "salesPerMonth",
              content: (
                <Text variant="h5" ink="neutralPrimary">
                  Penjualan Bulanan
                </Text>
              ),
              tableCellProps: {
                className: clsx(
                  classes.tableHeadingCell,
                  classes.salesPerMonthCell
                ),
              },
            },
            {
              id: "unit",
              content: (
                <Box ml={pxToRem(8)}>
                  <Text variant="h5" ink="neutralPrimary">
                    Satuan
                  </Text>
                </Box>
              ),
              tableCellProps: {
                className: clsx(classes.tableHeadingCell, classes.unitCell),
              },
            },
            {
              id: "action",
              content: "",
              tableCellProps: {
                className: classes.tableHeadingCell,
              },
            },
          ]}
          renderTableData={renderTableData}
        />
      </Card>
    </Box>
  );
};

export default SellInMPRecommendationSection;
