import * as React from "react";
import {
  Button,
  numberFormatter,
  pxToRem,
  Table,
  Text,
  useAppTheme,
  usePopoverState,
} from "@gada-saas/web-ui";
import { Box, Card, Popover, TableCell, TableRow } from "@material-ui/core";
import { Close as CloseIcon, Info as InfoIcon } from "@material-ui/icons";
import { useStyles } from "./styles";
import {
  useActiveStoreState,
  useGetInventoryMostPurchasedListQuery,
} from "@gada-saas/web-core";
import { useRouter } from "next/router";
import { Pages } from "@common/constants";

const MostPurchasedInventorySection = () => {
  const router = useRouter();
  const { palette } = useAppTheme();
  const classes = useStyles();
  const [iconBindings, popoverBindings] = usePopoverState<SVGSVGElement>();
  const { storeId } = useActiveStoreState();

  const { data } = useGetInventoryMostPurchasedListQuery(
    {
      page: 1,
      pageSize: 10,
      storeId,
    },
    { skip: !storeId, refetchOnMountOrArgChange: true }
  );

  const inventoryMostPurchasedList = React.useMemo(
    () => data?.data || [],
    [data?.data]
  );

  const renderTableData = React.useCallback(() => {
    return inventoryMostPurchasedList.map((val) => (
      <TableRow key={val.productVariantId}>
        <TableCell width="50%">
          <Box display="flex" alignItems="center">
            <Box marginRight={"16px"}>
              <img
                className={classes.productVariantImg}
                src={val.photoUrl || undefined}
                alt={val.productVariantName}
              />
            </Box>
            <Text>{val.productVariantName}</Text>
          </Box>
        </TableCell>
        <TableCell width="25%">
          {numberFormatter(val.totalSold)} {val.totalSoldUom}
        </TableCell>
        <TableCell width="25%" align="right">
          <Button
            variant="text"
            onClick={() => {
              router.push({
                pathname: Pages.INVENTORY_BULK_ADD,
                query: {
                  productVariantName: val.productVariantName,
                  productPhoto: val.photoUrl,
                  productVariantId: val.productVariantId,
                },
              });
            }}
          >
            Tambah Barang
          </Button>
        </TableCell>
      </TableRow>
    ));
  }, [classes.productVariantImg, inventoryMostPurchasedList, router]);

  if (inventoryMostPurchasedList.length <= 0) {
    return null;
  }

  return (
    <Box marginBottom={pxToRem(48)}>
      <Box display="flex" marginBottom={pxToRem(16)} alignItems="center">
        <Text variant="h3">Rekomendasi Barang Terlaris</Text>
        <InfoIcon
          className={classes.icon}
          htmlColor={palette.interface.neutral.secondary}
          {...iconBindings}
        />
        <Popover
          PaperProps={{
            className: classes.paperPopover,
          }}
          {...popoverBindings}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box display="flex" justifyContent="space-between" marginBottom={6}>
            <Text variant="h3">Rekomendasi Barang Terlaris</Text>
            <CloseIcon
              className={classes.icon}
              htmlColor={palette.interface.neutral.primary}
              onClick={popoverBindings.onClose}
            />
          </Box>
          <Text variant="body2" className={classes.popoverInfoDescription}>
            Barang-barang paling laku di daerah sekitar toko yang belum Anda
            miliki di Daftar Barang toko Anda.
          </Text>
        </Popover>
      </Box>

      <Card className={classes.card}>
        <Table
          containerClassName={classes.tableContainer}
          tableProps={{ stickyHeader: true }}
          tableHeadingCells={[
            {
              id: "productVariantName",
              content: "Nama Barang",
              tableCellProps: {
                className: classes.tableHeadingCell,
              },
            },
            {
              id: "salesPerMonth",
              content: "Penjualan Bulanan",
              tableCellProps: {
                className: classes.tableHeadingCell,
              },
            },
            {
              id: "action",
              content: "",
              tableCellProps: {
                className: classes.tableHeadingCell,
              },
            },
          ]}
          renderTableData={renderTableData}
        />
      </Card>
    </Box>
  );
};

export default MostPurchasedInventorySection;
